import React, { useCallback, useContext, useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { BaseContentBox } from '@pokemon/design.ui.containers.base-content-box'
import { AuthContextValue, AuthContext } from '../../utils/auth/context'
import { LoadingContainer, LoadingContext } from '@pokemon/design.ui.loading-spinner'
import { TotpSecretPayload, deleteMfa, getMfaStatus, getTotpSecret } from '../../utils/mfaApi'
import { useNavigate } from 'react-router'
import { Typography } from '@pokemon/design-v2.typography'
import { Button } from '@pokemon/design-v2.button'
import { TextField } from '@pokemon/design-v2.text-field'
import { Field, Form } from 'react-final-form'
import { EnrollmentModal } from './enrollment_modal'
import Validators from '../../utils/formValidators'
import { SuccessModal } from './success_modal'

export interface MfaPageProps {
  setError: (text?: string) => void,
  isChild?: boolean
}

enum MfaPageState {
  NeedsLoad,
  LoadStarted,
  MfaDisabled,
  MfaEnabled,
}

interface DisableMfaValues {
  totp: string
}

export function MfaPage (props: MfaPageProps) {
  const { accessToken, isChildView, selectedAccount } = useContext<AuthContextValue>(AuthContext)
  const { setLoading } = useContext(LoadingContext)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setError } = props
  const [mfaPageState, setMfaPageState] = useState(MfaPageState.NeedsLoad)
  const [enrollmentModalOpen, setEnrollmentModalOpen] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const [totpData, setTotpData] = useState<TotpSecretPayload | undefined>(undefined)
  const childGuid = isChildView ? selectedAccount?.guid : undefined

  const handlePromise = useCallback((promise: Promise<unknown>) => {
    setLoading(true)
    promise.catch(err => {
      console.error(err)
      setError()
    }).finally(() => {
      setLoading(false)
    })
  }, [setError, setLoading])

  const loadMfaStatus = useCallback(async () => {
    const status = await getMfaStatus(accessToken!.token, childGuid)
    const newPageState = status.data.mfa_method.includes('totp') ? MfaPageState.MfaEnabled : MfaPageState.MfaDisabled
    setMfaPageState(newPageState)
  }, [accessToken, childGuid])

  function validateCode (t: TFunction<'translation', undefined>) {
    return (code: any) => {
      return Validators.validateCode(code) ? null : t('mfa_code_invalid', 'Code must be 6 digits')
    }
  }

  const setupMFAInfo = async (accessToken: string) => {
    try {
      const data : TotpSecretPayload = (await getTotpSecret(accessToken, childGuid)).data
      setTotpData(data)
      setEnrollmentModalOpen(true)
    } catch (error: any) {
      console.error(error)
      setError()
    }
  }

  const disableMfa = useCallback(async (values: DisableMfaValues) => {
    try {
      await deleteMfa(accessToken!.token, {
        mfa_method: 'totp',
        totp: values.totp
      }, childGuid)
      setMfaPageState(MfaPageState.MfaDisabled)
    } catch (error: any) {
      if (error.response?.status === 400) {
        return { totp: t('mfa_code_incorrect', 'Code is incorrect, please check and try again') }
      }
      console.error(error)
      setError()
    }
  }, [t, accessToken, setError, childGuid])

  useEffect(() => {
    if (mfaPageState === MfaPageState.NeedsLoad) {
      setMfaPageState(MfaPageState.LoadStarted)
      handlePromise(loadMfaStatus())
    }
  }, [isChildView, mfaPageState, navigate, handlePromise, loadMfaStatus])

  return (
    <>
      <LoadingContainer altText={t('loading_spinner', 'Loading...')}>
        {mfaPageState === MfaPageState.MfaDisabled &&
          <BaseContentBox customWidth={700} autoSizeBox={true}
            title={<Typography variant="h5" component="h1">{isChildView ? t('mfa_intro_title_child', 'Help Protect Your Child\'s Account') : t('mfa_intro_title', 'Help Protect Your Account')}</Typography>}>
            <div style={{ textAlign: 'center' }}>
              <img alt='' aria-hidden='true' style={{ width: '218px', margin: 8 }} src={'https://static.prod.pokemon.com/images/mfa_zamazenta.png'} />
            </div>
            { props.isChild
              ? <><Typography variant='body1' style={{ marginBottom: '10px' }}>{ t('mfa_intro_text1_child', 'Multi-factor authentication is a security feature that helps protect your Child\'s Pokémon Trainer Club account by requiring an extra code every time they log in. You will need to install an authenticator app on your or your child\'s smartphone or other device. You or your child will then use that device to receive the authentication code needed to log in to their account each time.') }</Typography>
              <Typography variant='body1'>{ t('mfa_intro_text2_child', 'This setting can also be turned on at a later time from your Child\'s Pokémon Trainer Club account.') }</Typography></>
              : <><Typography variant='body1'>{ t('mfa_intro_text1', 'Multi-factor authentication is a security feature that helps protect your Pokémon Trainer Club account.') }</Typography>
              </> }
            <Button disableRipple data-testid="mfa-turn-on" text={t('mfa_turn_on', 'TURN ON')} onClick={() => handlePromise(setupMFAInfo(accessToken?.token!))} style={{ width: '100%', marginTop: 32 }}/>
          </BaseContentBox>
        }
        {mfaPageState === MfaPageState.MfaEnabled &&
          <BaseContentBox customWidth={700} autoSizeBox={true}
            title={<Typography variant="h5" component="h1">{t('mfa_success_title', 'Multi-factor Authentication Is On!')}</Typography>}>
              {props.isChild
                ? <>
                  <Typography paragraph variant='body1'>{t('mfa_success_text1_child', 'The next time you or your child logs in to their Pokémon Trainer Club account, they will be asked to enter the code from your authenticator app, together with their password.')}</Typography>
                  <Typography paragraph variant='body1'>{t('mfa_success_text2_disable_child', 'To turn off Multi-factor authentication, enter the generated code. Then, click Turn Off')}</Typography>
                  </>
                : <>
                  <Typography paragraph variant='body1'>{t('mfa_success_text1', 'You\'ll be asked to enter your password and the generated code from your authenticator app next time you log in.')}</Typography>
                  <Typography paragraph variant='body1'>{t('mfa_success_text2_disable', 'To turn off Multi-factor authentication, enter the generated code. Then, click Turn Off')}</Typography>
                  </>
                }

            <Form onSubmit={disableMfa} render={(form) => (
              <form onSubmit={form.handleSubmit}>
                <Field name="totp" validate={validateCode(t)}>
                  {({ input, meta }) => (
                    <TextField
                      id="mfa-turn-off-code"
                      type="text"
                      placeholder={t('mfa_turn_off_code_placeholder', 'Enter One Time Code')}
                      sx={{ width: 1 }}
                      errorText={meta.touched && (meta.error || (!form.modifiedSinceLastSubmit && meta.submitError))}
                      {...input}/>
                  )}
                </Field>
                <Button disableRipple
                  data-testid="mfa-turn-off"
                  type="submit"
                  disabled={form.submitting || form.hasValidationErrors || (form.hasSubmitErrors && !form.modifiedSinceLastSubmit)}
                  text={t('mfa_turn_off', 'TURN OFF')}
                  style={{ width: '100%', marginTop: 32 }}/>
              </form>
            )}/>
          </BaseContentBox>
        }
      </LoadingContainer>
      <EnrollmentModal copyData={ totpData?.totp_secret || '' } enrollmentTicket={ totpData?.enrollment_ticket || '' } qrData={ totpData?.qr_data || '' } open={ enrollmentModalOpen } onClose={ () => { setEnrollmentModalOpen(false) } } setError={ setError } onSuccess={ () => { setEnrollmentModalOpen(false); setSuccessModalOpen(true) } } isChild={ props.isChild } />
      <SuccessModal open={ successModalOpen } onClose={() => { setSuccessModalOpen(false); setMfaPageState(MfaPageState.NeedsLoad) }} isChild={props.isChild } />
    </>
  )
}
